export const vehicleTypes = {
  SCOOTER: {
    text: 'Scooter',
    value: 'P',
  },
  SCOOTER_PRO: {
    text: 'Scooter Pro',
    value: 'PP',
  },
  EBIKE: {
    text: 'E-Bike',
    value: 'E',
  },
  BIKE: {
    text: 'Bike',
    value: 'S',
  },
  COCO: {
    text: 'SuperCoco',
    value: 'C',
  },
  KENOTA: {
    text: 'Kenota',
    value: 'K',
  },
  MOPED: {
    text: 'Mopeds',
    value: 'M',
  },
}

export const vehicleTypesForOrg = {
  'SCOOTER': {
    text: 'Scooters',
    value: 'P',
  },
  'SCOOTER PRO': {
    text: 'Scooters Pro',
    value: 'PP',
  },
  'EBIKE': {
    text: 'E-Bikes',
    value: 'E',
  },
  'BIKE': {
    text: 'Bikes',
    value: 'S',
  },
  'COCO': {
    text: 'SuperCocos',
    value: 'C',
  },
  'KENOTA': {
    text: 'Kenotas',
    value: 'K',
  },
  'MOPED': {
    text: 'Mopeds',
    value: 'M',
  },
}

export const vehicleTypesUsingKey = {
  'SCOOTER': {
    text: 'Scooter',
    value: 'SCOOTER',
  },
  'SCOOTER PRO': {
    text: 'Scooter Pro',
    value: 'SCOOTER PRO',
  },
  'EBIKE': {
    text: 'E-Bike',
    value: 'EBIKE',
  },
  'BIKE': {
    text: 'Bike',
    value: 'BIKE',
  },
  'COCO': {
    text: 'SuperCoco',
    value: 'COCO',
  },
  'KENOTA': {
    text: 'Kenota',
    value: 'KENOTA',
  },
  'MOPED': {
    text: 'Moped',
    value: 'MOPED',
  },
}
